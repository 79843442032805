<template>
    <div class="center">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>
                    <router-link to="/">首页</router-link>
                </el-breadcrumb-item>
                <el-breadcrumb-item>店铺搜索</el-breadcrumb-item>

            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="storestreet p ma-to-20">
                <ul>
                    <li v-for="(item, index) in shop" :key="index">
                        <router-link :to="{ path: '/shop', query: { id: item.Id } }">
                            <div class="deoe">
                                <a class="deoe-pic-wrap"><img class="gejr lazy" data-original="" /></a>
                                <a>
                                    <div class="logoansome">
                                        <div class="neleu">
                                            <!-- <img class="lazy" data-original="" /> -->
                                        </div>
                                        <div class="inetrjol">
                                            <p class="names">{{ item.Name }}</p>
                                            <p class="main"></p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </router-link>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            categoryList: "", //分类列表
            categoryID: 0, // 分类id
            product: "", // 商品列表
            productList: "",
            total: 0, // 商品总量
            pageSize: 15, // 每页显示的商品数量
            currentPage: 1, //当前页码
            activeName: "-1", // 分类列表当前选中的id
            activeName2: "-1", // 分类列表当前选中的id
            search: "", // 搜索条件
            categoryList2: ["100元", "200元", "300元", "400元以上"],
            hotGoods: [],
            StartPrice: "", //起始价
            EndPrice: "", //结束价
            lists: ["综合", "销量", "价格升序", "价格降序"],
            curr: 0,
            prie: false,
            shop: []
        };
    },

    activated() {
        if (this.$route.query.search != undefined) {
            this.search = this.$route.query.search;
            console.log(this.search)
        }
        this.getData()
    },
    methods: {
        async getData() {
            var res = await this.postdata("/api/shop/ShopList", {
                "page": 1,
                "limit": 99,
                "queryName": this.search //搜索
            });
            if (res.code == 200) {
                this.shop = res.data.items
            }
        }
    }
}
</script>
<style>
.center {
    width: 1240px;
    margin: 0 auto;
}

.breadcrumb {
    height: 50px;
    background-color: white;
    padding-top: 16px;
}

.breadcrumb .el-breadcrumb {
    width: 1240px;
    line-height: 30px;
    font-size: 16px;
    margin: 0 auto;
}

.storestreet ul {
    overflow: hidden;
    margin-bottom: 40px
}

.storestreet ul li {
    float: left;
    width: 390px;
    border: 1px solid #e2e2e2;
    margin-right: 24px;
}

.storestreet ul .mar0 {
    margin-right: 0
}

.deoe .deoe-pic-wrap {
    display: block;
    width: 390px;
    height: 160px;
    margin-bottom: 6px;
    overflow: hidden;
}

.deoe .deoe-pic-wrap>img {
    vertical-align: top;
    width: 100%;
    height: 100%;
}

.deoe .logoansome {
    padding: 0 10px;
    overflow: hidden
}

.deoe .logoansome .neleu {
    float: left;
    padding-left: 10px
}

.deoe .logoansome .neleu img {
    width: 129px;
    height: 57px
}

.deoe .logoansome .inetrjol {
    width: 210px;
    padding-left: 20px;
    float: left;
    line-height: 31px
}

.deoe .logoansome .inetrjol p {
    color: #666666;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.deoe .logoansome .inetrjol .names {
    font-size: 16px;
    color: #333333
}
</style>